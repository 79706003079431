
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasePageContainer from '@/components/reports-v2/pages/BasePageContainer.vue';
import moment from 'moment';
import PageIndicators from '@/components/reports-v2/components/pages/PageIndicators.vue';
import TitlePageIndex from '@/components/reports-v2/components/pages/TitlePageIndex.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';
import LastSync from '@/components/LastSync.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import gdbx from '@/store/modules/gdbx';
import {ValueText} from '@/store/models.def';

@Component({
	components: {
		AutoResizeSticky,
		PageIndicators,
		TitlePageIndex,
		AgentsListFilter,
		CustomerListFilter,
		DateFilter,
		DateRangeFilter,
		StickyFilterContainer,
		LastSync,
    SelectFilter,
	},
})
export default class Customer extends BasePageContainer {
  public defaultCustomer: string | null = null;
	public selectedAgents: string[] = [];
	public selectedCustomers: string[] = [];
	public selectedAsOfDate: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('year').valueOf(),
		moment().endOf('day').valueOf(),
	];

  @Watch('customerList', { immediate: true })
  public onCustomerListChange(newVal: Array<ValueText<string>>) {
    if (newVal[0]) {
      this.defaultCustomer = newVal[0].value;
    }
  }

  public get customerList() {
    return gdbx.allCustomers;
  }


  public get pages() {
		return [
			[
				'Total Outstanding',
				'Total Due',
				'% Overdue',
				'Customer Outstanding',
				'Upcoming Due',
				'Due Accounts Receivable',
				'Customer Aging Summary',
				'Customer Aging Overview',
			],
			[
				'Customer Analysis',
				'Document Analysis (Invoice vs Payment Comparison)',
				'Customer Payment Analysis',
			],
			// [
			// 	'Avg Value Per Transaction',
			// 	'Avg Unit Per Transaction',
			// 	'Trade Over Revenue',
			// 	'Un-applied Amount',
			// ],
			// ['Sales Period Comparison', 'Sales Trend'],
		];
	}
	public mounted() {
		(this.$refs.stickyBottom as any).handleScroll();
	}
}
